import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const underacine = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Un déraciné" />
    <h3 className='underline-title'>Un déraciné</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Génie de notre race<br />
        Mbongo Nzapa Sésé Yayu<br />
        Père des saisons<br />
        Celui qui a durci la pierre<br />
        Gardien d'une larve égarée<br />
        Au pied d'une monstrueuse forêt<br />
        Je ne me rappelle plus <br />
        Comment mon père t'adorait au lever du jour<br />
        Comment mon oncle te portait ses prémices<br />
        Comment ma tante t'invoquait au déclin du soleil<br />
      </p>
      <p>
        J'ai l'envie d'accomplir les rites ancestraux<br />
        Les rites du culte de ma race<br />
        Je ne sais comment retrouver ton habitat<br />
        En quel lieu te rencontrer encore<br />
        Auprès de quelle source du village<br />
      </p>
      <p>
        Génie des forêts<br />
        Pour quel arbre as-tu prédilection?<br />
        Pour quel arbustre hantes-tu de préférence?<br />
      </p>
      <p>
        Je suis égaré<br />
        En pleine ville-lumière de totales ténèbres<br />
        Je ne sais comment ils t'adoraient<br />
      </p>
      <p>
        Je suis donc déraciné<br />
        Déraciné sur mon propre sol en érosion<br />
        Déraciné dans mon propre pays en convulsion<br />
        Déraciné dans ma famille en désagrégation<br />
        Je suis mortellement déraciné<br />
      </p>
    </div>
    <hr/>
    <p className="date-published">Bukavu, le 2 février 1977</p>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default underacine
